import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [2];

export const dictionary = {
		"/(main)": [8,[4]],
		"/(main)/404": [9,[4]],
		"/(main)/about": [10,[4]],
		"/(main)/accessibility": [11,[4]],
		"/(admin)/admin": [~5,[2]],
		"/(admin)/admin/preview/[emailType]": [6,[2]],
		"/(main)/bespoke": [12,[4]],
		"/(main)/blog": [13,[4]],
		"/(main)/blog/[article]": [14,[4]],
		"/(checkout)/checkout/shopping-bag": [~7,[3]],
		"/(main)/contact": [15,[4]],
		"/(main)/faqs": [16,[4]],
		"/(main)/privacy": [17,[4]],
		"/(main)/return-policy": [18,[4]],
		"/(main)/shop/[category=gender]": [19,[4]],
		"/(main)/shop/[category=gender]/[type]": [20,[4]],
		"/(main)/shop/[category=gender]/[type]/[product]": [21,[4]],
		"/(main)/shop/[category=gender]/[type]/[product]/[colour]": [22,[4]],
		"/(main)/terms": [23,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';